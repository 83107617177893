import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

const data = [
  {
    name: "Ediciones",
    testimonial: "+3",
  },
  {
    name: "Asistentes",
    testimonial: "+3500",
  },
  {
    name: "Equipos Participantes",
    testimonial: "+60",
  },

  {
    name: "Lanzamientos de cohetes de equipos participantes",
    testimonial: "+40",
  },
  {
    name: "Colaboradores y Patrocinadores",
    testimonial: "+40",
  },
  {
    name: "Universidades",
    testimonial: "+50",
  },
  {
    name: "Proyectos registrados en ENMICE 2023-2024",
    testimonial: "+60",
  },
  {
    name: "Equipos registrados en ENMICE 2023-2024",
    testimonial: "+50",
  },
  {
    name: "Red de jueces en cohetería a nivel nacional",
    testimonial: "1ra",
  },
];

export default function NumbersTestimonials() {
  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
          display: "flex", // Add flex display
          flexDirection: "column", // Ensure column direction
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            component="h2"
            variant="h2"
            sx={{ color: "text.primary", marginRight: 1 }}
          >
            ENMICE
          </Typography>
          <Typography
            component="h2"
            variant="h2"
            sx={{ color: "primary.light" }}
          >
            en números
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={2} justifyContent="center">
        {data.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex" }}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
                p: 1,
                border: 2,
                borderColor: "primary.light",
                // Efecto 3D y transición suave
                transform: "perspective(1000px)",
                transition: "all 0.3s ease",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                // Efecto al hacer hover
                "&:hover": {
                  cursor: "pointer",
                  dorder: 4,
                  transform:
                    "perspective(1000px) translateY(-8px) rotateX(5deg)",
                },
              }}
            >
              <CardContent>
                <Typography variant="h2" sx={{ color: "primary.light" }}>
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  pr: 2,
                }}
              >
                <CardHeader title={testimonial.name} />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
