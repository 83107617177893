import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const GradientButton = ({ to, children }) => {
  return (
    <Button
      component={Link}
      to={to}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        alignItems: "center",
        backgroundImage: "linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB)",
        border: 0,
        borderRadius: "8px",
        boxShadow: "rgba(151, 65, 252, 0.2) 0 15px 30px -5px",
        boxSizing: "border-box",
        color: "#FFFFFF",
        display: "flex",
        fontFamily: "Phantomsans, sans-serif",
        fontSize: { xs: "25px", md: "30px" },
        justifyContent: "center",
        lineHeight: "1em",
        maxWidth: "100%",
        minWidth: { xs: "150px", md: "205px" },
        maxHeight: "100%",
        minHeight: { xs: "40px", md: "60px" },
        padding: "3px",
        textDecoration: "none",
        userSelect: "none",
        WebkitUserSelect: "none",
        cursor: "pointer",
        transition: "all 300ms ease-in-out",
        "&:hover": {
          transform: "scale(1.05)",
          boxShadow: "rgba(47, 154, 255, 0.4) 0 20px 40px -10px",
          backgroundImage:
            "linear-gradient(144deg, rgba(60,235,255,1), rgba(10,181,198,1) 50%, rgba(0,137,150,1))",
        },
      }}
    >
      <span
        style={{
          backgroundColor: "rgb(5, 6, 45)",
          padding: "5px 24px",
          borderRadius: "6px",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          transition: "300ms",
        }}
      >
        {children}
      </span>
    </Button>
  );
};

export default GradientButton;
